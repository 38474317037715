.wrapper {
    background: rgb(255, 167, 17);
    background: url('assets/images/header-bg.svg'), linear-gradient(90deg, rgba(255, 167, 17, 1) 0%, rgba(255, 189, 17, 1) 100%);;
    background-position-x: -75%;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper-tailwind-compatible {
    background: rgb(255, 167, 17);
    background: url('assets/images/header-bg.svg'), linear-gradient(90deg, rgba(255, 167, 17, 1) 0%, rgba(255, 189, 17, 1) 100%);;
    background-position-x: -75%;
}

.content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.klingl-button-counting-down {
    margin-top: 4rem;
}

.klingl-button-counting-down:hover {
    outline: none;
    cursor: wait;
}

.klingl-again-button-wrapper {
    margin-top: 4rem;
}

.klingl-again-button-wrapper p {
    color: #4F4F4F;
    font-weight: bolder;
}

button.plain {
    width: 319px;
    height: 58px;

    background: #FFFFFF;
    border: 0;
    border-radius: 20px;
    color: #4F4F4F;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    cursor: pointer;
}

button.plain:hover {
    padding: 15px 40px;
    outline: 2px solid white;
    outline-offset: 2px;
}

.app-logo {
    width: 40vw;
}

.app-logo-container {
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 1);
}

/* 3. HEADER */
.header-bg-section {
    position: absolute;
    width: 100%;
    padding-top: 100%;
    top: 40%;
    left: -40%;
    transform: translateY(-50%);
    z-index: -1;
}

.header-bg-section::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-image: url(assets/images/header-bg.svg);
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}
